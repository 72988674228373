import React, {useEffect} from 'react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import koKR from 'antd/es/locale/ko_KR'
import './App.css'
import DefaultRoutes from './routes'
import {ConfigProvider} from 'antd'
import {CookiesProvider} from 'react-cookie'
import {useRecoilState} from 'recoil'
import {vendorState} from './states/vendor.state'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  }
})

function App() {
  const [, setVendor] = useRecoilState(vendorState)

  useEffect(() => {
    return () => {
      setVendor(null)
    }
  }, [])

  return (
    <ConfigProvider locale={koKR}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <DefaultRoutes />
        </CookiesProvider>
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
