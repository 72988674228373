/* eslint-disable security/detect-unsafe-regex */
import React, {forwardRef, RefObject, useEffect, useState} from 'react'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography
} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import {getProduct, IProduct} from '../../../../api/products'
import {getDeliveryTypes, IDeliveryType} from '../../../../api/deliveryTypes'
import styled from 'styled-components'
import {IPostOrder, postOrder} from '../../../../api/orders'
import ImageView from '../../../../components/ImageView'
import {useNavigate} from 'react-router'
import {getVendor} from '../../../../api/vendors'
import DaumPostcodeEmbed from 'react-daum-postcode'
import dayjs from 'dayjs'
import {getAddresss} from '../../../../api/address'
import {userState} from '../../../../states/user.state'
import {useRecoilState, useRecoilValue} from 'recoil'
import {vendorState} from '../../../../states/vendor.state'

interface ModalProps {
  checkedList?: number[]
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IProduct>
  onAction: (type: ActionType, record: Partial<IProduct>) => void | Promise<void>
}

const columns = [
  {
    title: '대표 이미지',
    dataIndex: ['productInfo', 'titleImage'],
    render: (titleImage) => <ImageView src={titleImage} width={123} height={123} isBorder />
  },
  {
    title: '상품명',
    dataIndex: ['productInfo', 'name'],
    render: (name, record) => name || record.productInfo.productName
  },
  {
    title: '수량',
    dataIndex: 'quantity'
  },
  {
    title: '옵션',
    dataIndex: 'basicOptionInfo',
    width: 500,
    render: (basicOptionInfo, {productInfo, iteOptionInfo}) =>
      productInfo.orderType === 'ITE' ? (
        <Row gutter={12} style={{maxHeight: 200, overflowY: 'auto'}}>
          <Col span={12} style={{marginBottom: '10px'}}>
            <Descriptions size="small" title="ITE 옵션 - 좌측" column={1} bordered>
              <Descriptions.Item label="쉘 타입">
                {iteOptionInfo?.leftInfo?.productShellTypes?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="쉘 색상">
                {iteOptionInfo?.leftInfo?.productShellColors?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="쉘 사이즈">
                {iteOptionInfo?.leftInfo?.productShellSizes?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="플레이트">
                {iteOptionInfo?.leftInfo?.productPlates?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="출력">{iteOptionInfo?.leftInfo?.productOutputs?.name || '-'}</Descriptions.Item>
              <Descriptions.Item label="벤트">{iteOptionInfo?.leftInfo?.productBents?.name || '-'}</Descriptions.Item>
              <Descriptions.Item label="벤트 스타일">
                {iteOptionInfo?.leftInfo?.productBentStyles?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="손잡이">
                {iteOptionInfo?.leftInfo?.productHandles?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="귀지 방지 옵션">
                {iteOptionInfo?.leftInfo?.productEarwaxOptions?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="코팅">
                {iteOptionInfo?.leftInfo?.productCoatings?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="카날 길이">
                {iteOptionInfo?.leftInfo?.productCanalLengths?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="무선">
                {iteOptionInfo?.leftInfo?.productWireless?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="청력도">
                <div style={{width: 100}}>
                  {iteOptionInfo?.leftInfo?.productAudiograms.length
                    ? iteOptionInfo?.leftInfo?.productAudiograms.map(({name, value}, index) => (
                        <Tag>{`${name}: ${value}`}</Tag>
                      ))
                    : '-'}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="볼륨 조절기">
                {iteOptionInfo?.leftInfo?.volumeControllers?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="프로그램 버튼">
                {iteOptionInfo?.leftInfo?.programButtons?.name || '-'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12} style={{marginBottom: '12px'}}>
            <Descriptions size="small" title="ITE 옵션 - 우측" column={1} bordered>
              <Descriptions.Item label="쉘 타입">
                {iteOptionInfo?.rightInfo?.productShellTypes?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="쉘 색상">
                {iteOptionInfo?.rightInfo?.productShellColors?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="쉘 사이즈">
                {iteOptionInfo?.rightInfo?.productShellSizes?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="플레이트">
                {iteOptionInfo?.rightInfo?.productPlates?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="출력">
                {iteOptionInfo?.rightInfo?.productOutputs?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="벤트">{iteOptionInfo?.rightInfo?.productBents?.name || '-'}</Descriptions.Item>
              <Descriptions.Item label="벤트 스타일">
                {iteOptionInfo?.rightInfo?.productBentStyles?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="손잡이">
                {iteOptionInfo?.rightInfo?.productHandles?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="귀지 방지 옵션">
                {iteOptionInfo?.rightInfo?.productEarwaxOptions?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="코팅">
                {iteOptionInfo?.rightInfo?.productCoatings?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="카날 길이">
                {iteOptionInfo?.rightInfo?.productCanalLengths?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="무선">
                {iteOptionInfo?.rightInfo?.productWireless?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="청력도">
                {iteOptionInfo?.rightInfo?.productAudiograms.length
                  ? iteOptionInfo?.rightInfo?.productAudiograms.map(({name, value}) => (
                      <>
                        <Tag>{`${name}: ${value}`}</Tag>
                        <br />
                      </>
                    ))
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="볼륨 조절기">
                {iteOptionInfo?.rightInfo?.volumeControllers?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="프로그램 버튼">
                {iteOptionInfo?.rightInfo?.programButtons?.name || '-'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      ) : (
        <Descriptions bordered size="small" column={1}>
          {productInfo?.grade && <Descriptions.Item label="등급">{productInfo?.grade}</Descriptions.Item>}
          {basicOptionInfo?.color && <Descriptions.Item label="컬러">{basicOptionInfo?.color}</Descriptions.Item>}
          {basicOptionInfo?.hooks?.length && (
            <Descriptions.Item label="후크">{basicOptionInfo?.hooks[0].name}</Descriptions.Item>
          )}
          {basicOptionInfo?.charges?.length && (
            <Descriptions.Item label="충전기">
              {basicOptionInfo?.charges.map(({name}) => <Tag>{name}</Tag>)}
            </Descriptions.Item>
          )}
          {basicOptionInfo?.acc?.length && (
            <Descriptions.Item label="돔">{basicOptionInfo?.acc.map(({name}) => <Tag>{name}</Tag>)}</Descriptions.Item>
          )}
          {basicOptionInfo?.receivers?.length && (
            <Descriptions.Item label="리시버">
              {basicOptionInfo?.receivers[0].product &&
                basicOptionInfo?.receivers?.map(
                  (receiver, index) =>
                    receiver?.product.productReceiverDirection && (
                      <Tag>
                        <>
                          {receiver?.product.productReceiverDirection.name}
                          {' / '}
                          {receiver?.product.productReceiverLength.name}
                          {receiver?.product.productReceiverPower &&
                            ` / ${receiver?.product.productReceiverPower.name}`}
                        </>
                      </Tag>
                    )
                )}
              {!basicOptionInfo?.receivers[0].product &&
                basicOptionInfo?.receivers?.map(
                  (receiver, index) =>
                    receiver?.productReceiverDirection && (
                      <Tag>
                        <>
                          {receiver?.productReceiverDirection.name}
                          {' / '}
                          {receiver?.productReceiverLength.name}
                          {receiver?.productReceiverPower && ` / ${receiver?.productReceiverPower.name}`}
                        </>
                      </Tag>
                    )
                )}
            </Descriptions.Item>
          )}
        </Descriptions>
      )
  },
  {
    title: '쿠폰',
    dataIndex: 'coupons',
    render: (coupons) => (coupons.length ? coupons.map(({name}) => <Tag>{name}</Tag>) : '-')
  }
]

function Show({record: recordData, checkedList}: any) {
  const [loading, setLoading] = useState(false)
  const [deliveryTypes, setDeliveryTypes] = useState<IDeliveryType[]>([])
  const [openPostcode, setOpenPostcode] = useState<boolean>(false)
  const [vendorInfo, setVendorInfo] = useState<any>({})
  const [address, setAddress] = useState<any>({})
  const [addressCount, setAddressCount] = useState<boolean>(false)
  const [deliveryTimes, setDeliveryTimes] = useState<any[]>([])
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(-1)
  const [form] = Form.useForm()
  const navigator = useNavigate()
  const [addressType, setAddressType] = useState<any>('main')
  const [record, setRecord] = useState<any>(null)
  const user: any = useRecoilValue(userState)
  const [vendor, setVendor] = useRecoilState(vendorState)

  async function handleFinish(values: IPostOrder) {
    setLoading(true)

    const success = () => {
      message.success('주문이 완료되었습니다.')
      navigator('../order')
    }

    try {
      values.sheetId = record.id
      await postOrder(values)
      success()
    } catch (e: any) {
      if (e.response && e.response.data.message === 'quantity_is_not_enough') {
        Modal.error({
          content: (
            <>
              재고가 부족하여 구매에 실패했습니다.
              <br />
              장바구니에서 구매 요청 재시도 시, 재고가 부족한 상품을 삭제할 수 있습니다.
            </>
          ),
          title: '재고 부족'
        })
      } else if (e.response && e.response.data.message === 'already_in_use') {
        Modal.error({title: '사용중인 계정', content: '다른 계정을 입력해주세요'})
      } else if (e.response) {
        if (e.response.data.message.includes('includes')) {
          success()
        } else Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
      }
      throw e
    } finally {
      setLoading(false)
    }
  }

  function handleOpenPostcode() {
    setOpenPostcode(true)
  }

  function handleClosePostcode() {
    setOpenPostcode(false)
  }

  function handleSelectAddress(data: any) {
    form.setFieldsValue({
      addressName: '',
      address: data.address,
      zipcode: data.zonecode,
      addressDetail: ''
    })
    setAddressCount(true)
    setOpenPostcode(false)
  }

  function handleInitializeAddress() {
    form.setFieldsValue({
      addressName: vendorInfo.name,
      address: vendorInfo.address,
      zipcode: vendorInfo.zipcode,
      addressDetail: ''
    })
    setAddressCount(false)
  }

  function handleInitializeSubAddress(targetId: string) {
    const targetAddress = address.find(({id}) => id === targetId)
    form.setFieldsValue({
      addressName: targetAddress.name,
      address: targetAddress.address,
      zipcode: targetAddress.zipcode,
      addressDetail: targetAddress.addressDetail
    })
    setAddressCount(false)
  }

  function handleInitializeInputAddress() {
    form.setFieldsValue({
      addressName: '',
      address: '',
      zipcode: '',
      addressDetail: ''
    })
    handleOpenPostcode()
    setAddressCount(false)
  }

  useEffect(() => {
    ;(async function () {
      const {data} = await getDeliveryTypes()

      let vendorData

      if (vendor) vendorData = vendor
      else {
        const res = await getVendor()
        setVendor(res)
        vendorData = res
      }

      const addressData = await getAddresss()
      const productsData: any = []

      for (let product of recordData.products) {
        const productData = await getProduct(product.productInfo.id)
        productsData.push({
          ...product,
          productInfo: {...product.productInfo, name: productData.description || productData.summary}
        })
      }

      // for (const checkedId of checkedList) {
      //   const cartItem = carts.find(({id}) => id === checkedId)

      //   if (cartItem)
      //     recordData.products[
      //       recordData.products.findIndex(({productInfo: {id}}) => id === cartItem.productInfo.id)
      //     ].productInfo = cartItem.productInfo
      // }

      setDeliveryTypes(data)
      setVendorInfo(vendorData)
      setAddress(addressData.data)

      setRecord({...recordData, products: productsData})

      form.setFieldsValue({
        deliveryFee: 0,
        receiverName: vendorData.name,
        receiverTel: vendorData.phone || vendorData.tel,
        addressName: vendorData.name,
        address: vendorData.address,
        zipcode: vendorData.zipcode,
        addressDetail: ''
      })
    })()
  }, [recordData, checkedList])

  useEffect(() => {
    setDeliveryTimes([
      ...recordData.deliveryTimeInfo.today.filter(({select}) => !select),
      ...recordData.deliveryTimeInfo.tomorrow
    ])
  }, [])

  if (!record) return <Skeleton />

  return (
    <Space direction="vertical" style={{width: '100%', padding: '0 28px 28px 28px'}}>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item hidden name="sheetId">
          <Input />
        </Form.Item>
        <Row gutter={[20, 40]}>
          <Col span={24}>
            <Table columns={columns} dataSource={record.products} pagination={false} />
          </Col>
          {user.isPricePublic && (
            <Col span={24} style={{marginBottom: 20}}>
              <Card>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>결제금액 : </div>
                  <Space>
                    {/* {record.amount < record.originalAmount && (
                    <>
                      <div style={{color: 'gray', fontSize: 18, fontWeight: 'bold', textDecoration: 'line-through'}}>
                        {record.originalAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        <ArrowRightOutlined style={{margin: '0 3px 0 8px'}} />
                      </div>
                    </>
                  )} */}
                    <div style={{color: process.env.REACT_APP_THEME_COLOR, fontSize: 20, fontWeight: 'bold'}}>
                      {record.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                    원
                  </Space>
                </div>
              </Card>
            </Col>
          )}
          <Col span={24}>
            <Row gutter={15}>
              <Col span={24}>
                <Typography.Title level={4}>수령자 정보</Typography.Title>
              </Col>
              <Col span={12}>
                <Form.Item label="수령자명" name="receiverName">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="수령자 전화번호" name="receiverTel">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider />
              </Col>
              <Col span={24} style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography.Title level={4}>수령지 정보</Typography.Title>
                <Space>
                  {addressType === 'input' && <Button onClick={handleInitializeInputAddress}>주소 선택하기</Button>}
                  <Select
                    defaultValue="main"
                    onChange={(val) => {
                      switch (val) {
                        case 'main':
                          handleInitializeAddress()
                          break
                        case 'input':
                          handleInitializeInputAddress()
                          break
                        default:
                          handleInitializeSubAddress(val)
                          break
                      }
                      setAddressType(val)
                    }}
                  >
                    <Select.Option value="main">메인 주소</Select.Option>
                    {address.map((addressItem) => (
                      <Select.Option value={addressItem.id}>{addressItem.name}</Select.Option>
                    ))}
                    {/* <Select.Option value="input">직접 입력</Select.Option> */}
                  </Select>
                </Space>
              </Col>
              <Col span={8}>
                <Form.Item label="수령지명" name="addressName">
                  <Input readOnly={addressType !== 'input'} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="주소" name="address" rules={[{required: true, message: '주소를 입력하세요'}]}>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="우편번호"
                  name="zipcode"
                  rules={[
                    {required: true, message: '우편 번호를 입력하세요'},
                    {pattern: /^\d{5,6}$/, message: '우편번호 형식이 올바르지 않습니다'}
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="상세주소" name="addressDetail">
                  <Input readOnly={addressType !== 'input'} />
                </Form.Item>
              </Col>
              {addressCount && (
                <Col span={24}>
                  <Form.Item
                    name="personalInfo"
                    rules={[{required: true, message: '개인정보 제공에 동의해주세요.'}]}
                    valuePropName="checked"
                  >
                    <Checkbox>개인정보 제공에 동의합니다.</Checkbox>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Divider />
              </Col>
              <Col span={24}>
                <Typography.Title level={4}>배송 정보</Typography.Title>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="출하 유형"
                  name="deliveryType"
                  rules={[
                    {required: true, message: '출하 유형을 선택하세요'},
                    {
                      validator: (_, val) =>
                        val === 1 || val === 5
                          ? Promise.resolve()
                          : Promise.reject(new Error('추가비용이 발생할 수 있습니다.')),
                      warningOnly: true
                    }
                  ]}
                >
                  <Select onChange={(val) => setSelectedDeliveryType(val)}>
                    {deliveryTypes.map(
                      (type) =>
                        ((recordData.products.find(({productInfo: {orderType}}) => orderType === 'ITE') &&
                          type.id === 1) ||
                          recordData.products.find(({productInfo: {orderType}}) => orderType !== 'ITE')) && (
                          <Select.Option key={type.id} value={type.id}>
                            {type.name}
                          </Select.Option>
                        )
                    )}
                  </Select>
                </Form.Item>
                {selectedDeliveryType !== -1 && selectedDeliveryType !== 1 && (
                  <>
                    <Form.Item name={['deliveryTime', 'date']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['deliveryTime', 'time']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item label="배송 시간">
                      <Select
                        onChange={(val) => {
                          val = JSON.parse(val)
                          form.setFieldsValue({
                            deliveryTime: {
                              date: val.date,
                              time: val.time
                            }
                          })
                        }}
                      >
                        {deliveryTimes?.map((time) => (
                          <Select.Option
                            disabled={
                              (time.time === '10:30' || time.date === dayjs().format('YYYY-MM-DD')) &&
                              record?.products.find(({productInfo: {orderType}}) => orderType === 'ITE')
                            }
                            value={JSON.stringify(time)}
                          >{`${dayjs(time.date).format('MM월 DD일')} ${time.time}`}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                )}
                {selectedDeliveryType !== -1 && selectedDeliveryType !== 1 && selectedDeliveryType !== 5 && (
                  <Form.Item
                    name="addFee"
                    rules={[{required: true, message: '추가비용 발생에 동의해주세요.'}]}
                    valuePropName="checked"
                  >
                    <Checkbox>추가비용 발생에 동의합니다.</Checkbox>
                  </Form.Item>
                )}
              </Col>
              <Col span={12}>
                <Form.Item label="배송 수수료" name="deliveryFee" hidden>
                  <InputNumber style={{width: '100%'}} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <BuyButtonArea>
          <Button size="large" shape="round" type="primary" onClick={() => form.submit()} loading={loading}>
            구매하기
          </Button>
        </BuyButtonArea>
      </Form>
      <Modal title="주소 검색" open={openPostcode} onCancel={handleClosePostcode} footer={null}>
        <DaumPostcodeEmbed onComplete={handleSelectAddress} autoClose={false} />
      </Modal>
    </Space>
  )
}

const OrderModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const {title = '주문서 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  return (
    <BasicTableModal
      maskClosable={false}
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={1000}
      onAction={onAction}
      render={(type, record) => {
        return (
          <Form form={form}>
            <Show record={record} checkedList={props.checkedList} />
          </Form>
        )
      }}
    />
  )
})

const BuyButtonArea = styled(Form.Item)`
  width: 100%;
  margin: 20px 0 -10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default OrderModal
